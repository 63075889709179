import Loadable from 'react-loadable';
import Spinner from 'src/components/Spinner';

export default Loadable({
    loader: () =>
        import('react-dates/initialize')
            .then(() => import('./react-dates-override.css'))
            .then(() => import('react-dates'))
            .then(module => module.DateRangePicker),
    loading: Spinner
});
