import React, { Fragment } from 'react';
import classes from './overrides.css';
import { array, instanceOf } from 'prop-types';
import TableWrapper from 'src/components/TableWrapper';
import { TableController } from 'fronds/Components';
import DateRange from 'src/components/DateRange';
import QueryParams from 'src/util/urlParams/queryParams';
import { overrideDateRanges } from 'src/constants/dateRanges';
import PageHeader from 'src/components/PageHeader';
import overrideStatusDisplayAll from 'src/constants/overrideStatusDisplayAll';
import Splash from 'src/components/Splash';
import EmptyTable from 'src/icons/emptytable.svg';
import TableRows from 'src/util/tableRows';
import { resultsPerPageDropdownOptions } from 'src/constants/resultsPerPageOptions';
const isEqual = require('lodash/isEqual');
import DropdownSelectWrapper from 'src/components/DropdownSelectWrapper';
import PaginationWrapper from 'src/components/PaginationWrapper';

const defaultStatus = 'Pending';

function OverridesPage(props) {
    const { tableRows, statuses, queryParams, OverrideHeaders } = props;

    function changeFilterOption(option) {
        queryParams
            .setFilterParams(option.value)
            .setPaginationParams(1)
            .setURLParams();
    }

    function handleDateChange(dateRange) {
        queryParams
            .setDateParams(dateRange)
            .setPaginationParams(1)
            .setURLParams();
    }

    function handleColumnSearch(search, header) {
        queryParams
            .setColumnSearchParam(header, search, headers)
            .setColumnComparatorParams(header, headers)
            .setPaginationParams(1)
            .setURLParams();
    }

    function handleSort(sort, asc) {
        queryParams
            .setSortParams(sort, asc)
            .setPaginationParams(1)
            .setURLParams();
    }

    function handleResultsPerPageChange(resultsPerPage) {
        queryParams
            .setResultsPerPageParam(resultsPerPage.value)
            .setPaginationParams(1)
            .setURLParams();
    }

    const onHeaderChange = selectedItems => {
        if (selectedItems.length === 0) return;
        queryParams.setHeaders(selectedItems).setURLParams();
    };

    const headers = OverrideHeaders.filter(header => {
        // Make sure headers exist as a string
        if (
            queryParams.query &&
            typeof queryParams.query.headers === 'string'
        ) {
            const queryHeaders = queryParams.query.headers.split(',');
            if (queryHeaders.includes(header.value) || header.static) {
                return header;
            }
        }
    });

    const initialColumnSearch = queryParams.getColumnSearch(headers);

    const fallback =
        queryParams.filter &&
        queryParams.filter == overrideStatusDisplayAll.value ? (
            <Splash
                message={
                    'No overrides were created within the current date range'
                }
                callToAction={'Try a Different Date Range'}
                action={() => document.getElementById('startDate').focus()}
                image={<EmptyTable />}
            />
        ) : (
            <Splash
                message={'The current search does not match any overrides'}
                callToAction={'View All Overrides'}
                action={() => changeFilterOption(overrideStatusDisplayAll)}
                image={<EmptyTable />}
            />
        );

    const headerProps = {
        initialSort: queryParams.sort,
        handleSort,
        initialColumnSearch,
        handleColumnSearch
    };

    return (
        <div className={classes.root}>
            <PageHeader text={'Overrides'} />
            <TableController
                inheritedClass={classes.tableController}
                left={
                    <Fragment>
                        <DateRange
                            label={'Override Date Range'}
                            currentStartDate={queryParams.startDate}
                            currentEndDate={queryParams.endDate}
                            onFocusNull={handleDateChange}
                            presets={Object.keys(overrideDateRanges).map(
                                key => overrideDateRanges[key]
                            )}
                        />
                        <DropdownSelectWrapper
                            onSelectionChange={opt => changeFilterOption(opt)}
                            listItems={statuses}
                            isClearable={false}
                            label={'Filter by Status'}
                            selectedValues={
                                statuses.find(
                                    filter =>
                                        filter.value ===
                                        parseInt(queryParams.filter)
                                ) ||
                                statuses.find(
                                    filter => filter.label === defaultStatus
                                ) ||
                                statuses.find(
                                    filter =>
                                        filter.value ===
                                        overrideStatusDisplayAll.value
                                )
                            }
                        />
                        <DropdownSelectWrapper
                            selectedValues={OverrideHeaders.filter(
                                baseHeader => {
                                    return headers.find(header => {
                                        return isEqual(baseHeader, header);
                                    });
                                }
                            )}
                            listItems={OverrideHeaders.filter(header => {
                                return !header.static;
                            })}
                            placeholder={'Set headers'}
                            onSelectionChange={onHeaderChange}
                            isMulti={true}
                            isClearable={false}
                            hideSelectedOptions={false}
                            controlShouldRenderValue={false}
                            isOptionDisabled={option =>
                                queryParams.headers.length <= 4 &&
                                queryParams.headers.find(header =>
                                    isEqual(header, option.value)
                                )
                            }
                        />
                    </Fragment>
                }
                right={
                    <>
                        <DropdownSelectWrapper
                            label={'Page Size'}
                            inheritedClass={'mini'}
                            listItems={resultsPerPageDropdownOptions}
                            selectedValues={
                                resultsPerPageDropdownOptions.find(
                                    opt =>
                                        opt.value === queryParams.resultsPerPage
                                ) || resultsPerPageDropdownOptions[0]
                            }
                            onSelectionChange={handleResultsPerPageChange}
                        />
                        <PaginationWrapper
                            totalRows={
                                tableRows.filteredRows
                                    ? tableRows.filteredRows.length
                                    : 0
                            }
                            handlePagination={page => {
                                queryParams
                                    .setPaginationParams(parseInt(page))
                                    .setURLParams();
                            }}
                            currentPage={queryParams.pageNumber}
                            pageSize={queryParams.resultsPerPage}
                        />
                    </>
                }
            />
            <TableWrapper
                rows={tableRows.getPage(queryParams.pageNumber)}
                headers={headers}
                headerProps={headerProps}
                fallback={fallback}
                primaryKey={'invoiceNumber'}
            />
        </div>
    );
}

OverridesPage.propTypes = {
    overrides: array,
    statuses: array.isRequired,
    queryParams: instanceOf(QueryParams),
    headers: array,
    tableRows: instanceOf(TableRows),
    OverrideHeaders: array
};

export default OverridesPage;
