import React from 'react';
import { bool } from 'prop-types';
import Loader from 'feather/loader';
import Check from 'feather/check';
import AlertCircle from 'feather/alert-circle';
import classes from './spinner.css';

function Spinner(props) {
    const { loading = true, error } = props;

    return (
        <div className={classes.root}>
            {loading ? (
                <span className={classes.loader}>
                    <Loader />
                </span>
            ) : error ? (
                <span className={classes.error}>
                    <AlertCircle />
                </span>
            ) : (
                <Check />
            )}
        </div>
    );
}

Spinner.propTypes = {
    loading: bool,
    error: bool
};

export default Spinner;
