import React, { useState, useEffect } from 'react';
import {
    string,
    func,
    arrayOf,
    shape,
    instanceOf,
    oneOfType,
    number,
    array
} from 'prop-types';
import moment from 'moment';
import classes from './dateRange.css';
import Calendar from 'feather/calendar';
import dateRanges from 'src/constants/dateRanges';
import LazyDateRangePicker from './lazyDateRangePicker';
import focusCalendar from 'src/util/focusCalendar';

import { Button } from 'fronds/Controllers';

function isSameDay(a, b) {
    a = moment.unix(a);
    b = moment.unix(b);
    return moment(b).isSame(a, 'day');
}

function RenderDatePresets(props) {
    const { presets, startDate, endDate, onClick, onClose } = props;
    return (
        <div className={classes.calendarButtonWrapper}>
            <div className={classes.dateRangePresetPanel}>
                {presets.map(({ text, start, end }) => {
                    const selected =
                        isSameDay(start, startDate) && isSameDay(end, endDate);
                    return (
                        <div
                            className={classes.dateRangePresetButton}
                            key={text}
                        >
                            <Button
                                text={text}
                                className={selected ? 'selected' : undefined}
                                onClick={() => onClick(start, end)}
                            >
                                {text}
                            </Button>
                        </div>
                    );
                })}
            </div>
            <Button
                inheritedClass={classes.goButton}
                disabled={!startDate || !endDate}
                onClick={onClose}
            >
                Go
            </Button>
        </div>
    );
}

RenderDatePresets.propTypes = {
    presets: array,
    startDate: oneOfType([instanceOf(moment), string, number]),
    endDate: oneOfType([instanceOf(moment), string, number]),
    onClick: func,
    onClose: func
};

function DateRange(props) {
    let {
        handleChange = () => {},
        currentStartDate = dateRanges.defaultDateRange.start,
        currentEndDate = dateRanges.defaultDateRange.end,
        presets = Object.keys(dateRanges).map(key => dateRanges[key]),
        label,
        onFocusNull = () => {},
        ...restProps
    } = props;

    let [focusedInput, setFocusedInput] = useState();

    let [startDate, setStartDate] = useState(currentStartDate);
    let [endDate, setEndDate] = useState(currentEndDate);

    useEffect(() => {
        setDatesInUnix({
            startDate: currentStartDate,
            endDate: currentEndDate
        });
    }, [currentEndDate, currentStartDate]);

    useEffect(() => {
        if (focusedInput === null && startDate && endDate) {
            const sameStart = isSameDay(startDate, currentStartDate);
            const sameEnd = isSameDay(endDate, currentEndDate);
            if (!sameStart || !sameEnd) {
                returnDateObject({ startDate, endDate }, onFocusNull);
                return;
            }
        }
    }, [focusedInput]);

    function returnDateObject(dateRange, cb) {
        if (dateRange.startDate && dateRange.endDate) {
            const startDate = dateRange.startDate;
            const endDate = dateRange.endDate;
            cb({ startDate, endDate });
        }
    }

    function getCalendarInfo() {
        return (
            <RenderDatePresets
                startDate={startDate}
                endDate={endDate}
                presets={presets}
                onClick={(start, end) => {
                    setStartDate(start);
                    setEndDate(end);
                    setFocusedInput(null);
                }}
                onClose={() => {
                    setFocusedInput(null);
                }}
            />
        );
    }

    const setDatesInUnix = dates => {
        function convertToUnixTimestamp(date, isEnd) {
            let validDate;
            // If this is not a moment object, we assume it's a date string or a unix time stamp
            if (!moment.isMoment(date)) {
                if (typeof date === 'string') {
                    // If the date is a string, ex '08-01-2019' see if it can be used to create a valid moment object
                    // If so, create a moment object and get the unix timestamp
                    // If not, just use the date itself, which we assume is a unix timestamps formatted as a string, ex '1564642800'

                    // If end date, get end of day before conversion
                    validDate = moment(date).isValid()
                        ? isEnd
                            ? moment(date)
                                  .endOf('day')
                                  .unix()
                            : moment(date).unix()
                        : date;
                } else {
                    // If date is a number, assume it's a unix time stamp and just use that!

                    // If end of day, need to calculate end of day, then convert back to unix
                    if (isEnd)
                        date = moment
                            .unix(date)
                            .endOf('day')
                            .unix();
                    validDate = date;
                }
            } else {
                // If it's a moment object, extract the unix time stamp from it
                if (isEnd) date = date.endOf('day');
                validDate = date.unix();
            }

            return validDate;
        }

        if (dates.startDate) {
            const validStartDate = convertToUnixTimestamp(dates.startDate);
            setStartDate(validStartDate);
        } else {
            setStartDate(null);
        }

        if (dates.endDate) {
            const validEndDate = convertToUnixTimestamp(dates.endDate, true);
            setEndDate(validEndDate);
        } else {
            setEndDate(null);
        }
    };

    return (
        <div className={classes.root}>
            {label ? (
                <div
                    className={classes.label}
                    onClick={() => document.getElementById('startDate').focus()}
                >
                    {label}
                </div>
            ) : null}
            <div className={classes.calendarWrapper}>
                <span className={classes.icon} onClick={() => focusCalendar()}>
                    <Calendar size={18} />
                </span>
                <LazyDateRangePicker
                    startDate={startDate ? moment.unix(startDate) : null}
                    startDateId="startDate"
                    endDate={endDate ? moment.unix(endDate) : null}
                    minimumNights={0}
                    endDateId="endDate"
                    renderCalendarInfo={getCalendarInfo}
                    onDatesChange={dateRange => {
                        setDatesInUnix(dateRange);
                        handleChange(dateRange);
                    }}
                    appendToBody
                    hideKeyboardShortcutsPanel={true}
                    keepOpenOnDateSelect={true}
                    focusedInput={focusedInput}
                    isOutsideRange={() => false}
                    onFocusChange={focusedInput => {
                        setFocusedInput(focusedInput);
                    }}
                    {...restProps}
                />
            </div>
        </div>
    );
}

DateRange.propTypes = {
    handleChange: func,
    label: string,
    currentEndDate: oneOfType([string, number, instanceOf(moment)]),
    currentStartDate: oneOfType([string, number, instanceOf(moment)]),
    initializeDate: func,
    presets: arrayOf(
        shape({
            text: string,
            start: number,
            end: number
        })
    ),
    onFocusNull: func
};

export default DateRange;
